import { memo } from 'react';
import { Autocomplete, Button, Stack, TextField, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

import SearchBar from './SearchBar';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { ELementWrapper } from './styledComponents';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { userSelectors } from 'store/ducks/user';

const HeaderList = ({
  title,
  to,
  buttonTitle,
  searchParams,
  setSearchParams,
  isDisplaySearchBar,
  filter,
  setFilter,
  groups,
  calendarDataLoading,
  handleSearch,
}) => {
  const navigate = useNavigate();
  const currentRole = useSelector(userSelectors.getCurrentRole());

  const isAdmin = currentRole === 'Admin';

  const goBack = () => navigate(-1);
  const { t } = useTranslation();

  return (
    <>
      <ELementWrapper
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        bgcolor="extra.white"
        p={2}
        borderRadius={2}
        sx={{
          width: '100%',
        }}
      >
        <Stack gap={2} direction="row" justifyContent="center" alignItems="center">
          <Button variant="contained" startIcon={<ArrowBackIosNewOutlinedIcon />} LinkComponent={Link} onClick={goBack}>
            {t('base.buttons.back')}
          </Button>
          <Typography variant="h4" color="text.main">
            {title}
          </Typography>
          {isAdmin && filter?.isShown && (
            <Autocomplete
              value={filter?.value}
              loading={calendarDataLoading}
              onChange={(event, option) => setFilter({ ...filter, value: option })}
              options={groups}
              isOptionEqualToValue={(option, value) => option.resourceId === value.resourceId}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.resourceId}>
                    {option.resourceTitle}
                  </li>
                );
              }}
              getOptionLabel={(option) => option.resourceTitle}
              sx={{
                width: 200,
                '&.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input': {
                  padding: '6.5px 4px 6.5px 5px',
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  label={t('types.group.name')}
                  placeholder={t('base.placeholders.choose')}
                />
              )}
            />
          )}
        </Stack>
        <Stack direction="row" gap={3}>
          {isDisplaySearchBar && (
            <SearchBar setSearchParams={setSearchParams} handleSearch={handleSearch} searchParams={searchParams} />
          )}
          {to && (
            <Button LinkComponent={Link} variant="contained" to={to}>
              {buttonTitle}
            </Button>
          )}
        </Stack>
      </ELementWrapper>
    </>
  );
};
export default memo(HeaderList);
