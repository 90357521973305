import { FormControl, MenuItem, Stack, Typography } from '@mui/material';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'views/form/Select';

const SortPrograms = ({ sortBy, setSortBy }) => {
  const { t } = useTranslation();

  const handleSortByChange = (event) => {
    setSortBy(event);
  };

  return (
    <Stack direction="row" gap={2} alignItems="center">
      <Typography variant="body2" color="text.main">
        {t('sortPanel.sortBy')}
      </Typography>
      <FormControl>
        <Select
          id="programsSortBy"
          value={sortBy}
          displayEmpty
          onChange={handleSortByChange}
          sx={{
            fontSize: 14,
            '& .MuiSelect-select': {
              padding: '4px 16px',
            },
          }}
        >
          <MenuItem sx={{ fontSize: 14 }} value="Default">
            {t('sortPanel.default')}
          </MenuItem>
          <MenuItem sx={{ fontSize: 14 }} value="A-Z">
            {t('sortPanel.az')}
          </MenuItem>
          <MenuItem sx={{ fontSize: 14 }} value="Z-A">
            {t('sortPanel.za')}
          </MenuItem>
        </Select>
      </FormControl>
    </Stack>
  );
};

export default memo(SortPrograms);
