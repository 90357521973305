import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import store from 'store';
import { userSelectors } from 'store/ducks/user';

import Loader from 'views/common/Loader';
import HeaderList from 'views/common/HeaderList';
import ProgramsList from './components/programs/ProgramsList';

import {
  PROGRAMS_FETCH_BY_ROLES,
  PROGRAMS_IS_LOADING_BY_ROLES,
  PROGRAMS_SELECTORS_BY_ROLES,
} from 'utils/constants/program';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PageViewPaperVioletLight, PageWrapper } from 'views/common/styledComponents';
import ProgramsListTopBar from '../../common/ProgramsListTopBar';
import { Stack, useMediaQuery } from '@mui/material';
import { DESKTOP_VIEW, MOBILE_VIEW } from 'utils/constants/common';
import HeaderPageMobile from 'views/common/HeaderPageMobile';
import NoProgram from './components/programs/NoProgram';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '@sentry/react';
import Error from 'views/common/Error';
import { searchItems, sortPrograms } from 'utils/helpers';

const ProgramsPage = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const searchPhrase = searchParams.get('q') || '';

  const { t } = useTranslation();

  const currentRole = useSelector(userSelectors.getCurrentRole());
  const programs = useSelector(PROGRAMS_SELECTORS_BY_ROLES[currentRole]);
  const isLoading = useSelector(PROGRAMS_IS_LOADING_BY_ROLES[currentRole]);

  const [sortBy, setSortBy] = useState('Default');

  const isDesktop = useMediaQuery(DESKTOP_VIEW);
  const isMobile = useMediaQuery(MOBILE_VIEW);

  const handleSearch = () => {
    return searchItems(programs, searchPhrase);
  };

  const filteredPrograms = handleSearch();
  const sortedPrograms = sortPrograms(filteredPrograms, sortBy);

  const handleCardClick = (programId) => {
    navigate(`/programs/${programId}`);
  };

  useEffect(() => {
    programs?.length === 0 && store.dispatch(PROGRAMS_FETCH_BY_ROLES[currentRole]);
  }, [currentRole]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PageWrapper>
      {isDesktop && (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <HeaderList
            title={t('types.program.namePlural')}
            setSearchParams={setSearchParams}
            handleSearch={handleSearch}
            searchParams={searchParams}
            isDisplaySearchBar
          />
        </ErrorBoundary>
      )}
      {!isDesktop && (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <HeaderPageMobile
            handleSearch={handleSearch}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            isDisplaySearchBar
          />
        </ErrorBoundary>
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <PageViewPaperVioletLight>
          {programs.length !== 0 ? (
            <Stack height="100%">
              <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
                <ProgramsListTopBar sortBy={sortBy} setSortBy={setSortBy} hideTopBar={isMobile} />
              </ErrorBoundary>
              <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
                <ProgramsList programs={sortedPrograms} handleCardClick={handleCardClick} />
              </ErrorBoundary>
            </Stack>
          ) : (
            <NoProgram />
          )}
        </PageViewPaperVioletLight>
      )}
    </PageWrapper>
  );
};

export default memo(ProgramsPage);
