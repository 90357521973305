import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import store from 'store';
import { userSelectors } from 'store/ducks/user';
import { GROUPS_FETCH_BY_ROLES, GROUPS_IS_LOADING_BY_ROLES, GROUPS_SELECTORS_BY_ROLES } from 'utils/constants/grades';
import HeaderList from 'views/common/HeaderList';
import Loader from 'views/common/Loader';
import { PageViewPaperVioletLight, PageWrapper } from 'views/common/styledComponents';
import ProgramsList from '../programs/components/programs/ProgramsList';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ProgramsListTopBar from 'views/common/ProgramsListTopBar';
import { Stack, useMediaQuery } from '@mui/material';
import { DESKTOP_VIEW, MOBILE_VIEW } from 'utils/constants/common';
import HeaderPageMobile from 'views/common/HeaderPageMobile';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '@sentry/react';
import Error from 'views/common/Error';
import { searchItems, sortPrograms } from 'utils/helpers';

const GradeBookGroupsListPage = () => {
  const navigate = useNavigate();
  const currentRole = useSelector(userSelectors.getCurrentRole());
  const groups = useSelector(GROUPS_SELECTORS_BY_ROLES[currentRole]);
  const groupsLoading = useSelector(GROUPS_IS_LOADING_BY_ROLES[currentRole]);

  const [searchParams, setSearchParams] = useSearchParams();

  const searchPhrase = searchParams.get('q') || '';

  const [sortBy, setSortBy] = useState('Default');

  const isDesktop = useMediaQuery(DESKTOP_VIEW);
  const isMobile = useMediaQuery(MOBILE_VIEW);

  const { t } = useTranslation();

  const handleSearch = () => {
    return searchItems(groups, searchPhrase);
  };

  const filteredGroups = handleSearch();
  const sortedGroups = sortPrograms(filteredGroups, sortBy);

  const handleCardClick = (groupId) => {
    navigate(`/grade-book/${groupId}`);
  };

  useEffect(() => {
    store.dispatch(GROUPS_FETCH_BY_ROLES[currentRole]);
  }, [currentRole]);

  return (
    <PageWrapper>
      {isDesktop && (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <HeaderList
            title={t('types.grade.book')}
            isDisplaySearchBar
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
        </ErrorBoundary>
      )}
      {!isDesktop && (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <HeaderPageMobile searchParams={searchParams} setSearchParams={setSearchParams} isDisplaySearchBar />
        </ErrorBoundary>
      )}
      <PageViewPaperVioletLight>
        {groupsLoading && <Loader />}
        {!groupsLoading && (
          <Stack height="100%">
            <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
              <ProgramsListTopBar sortBy={sortBy} setSortBy={setSortBy} hideTopBar={isMobile} />
            </ErrorBoundary>
            <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
              <ProgramsList programs={sortedGroups} handleCardClick={handleCardClick} />
            </ErrorBoundary>
          </Stack>
        )}
      </PageViewPaperVioletLight>
    </PageWrapper>
  );
};

export default memo(GradeBookGroupsListPage);
