import { Stack, useMediaQuery } from '@mui/material';
import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import store from 'store';
import { userSelectors } from 'store/ducks/user';
import {
  PROGRAMS_FETCH_BY_ROLES,
  PROGRAMS_IS_LOADING_BY_ROLES,
  PROGRAMS_SELECTORS_BY_ROLES,
} from 'utils/constants/program';
import HeaderList from 'views/common/HeaderList';
import SubmissionsNavigation from './components/SubmissionsNavigation';
import Loader from 'views/common/Loader';
import { DESKTOP_VIEW } from 'utils/constants/common';
import { PageWrapper } from 'views/common/styledComponents';
import NoGroup from 'views/common/NoGroup';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '@sentry/react';
import Error from 'views/common/Error';

const SubmissionsPage = () => {
  const currentRole = useSelector(userSelectors.getCurrentRole());
  const groups = useSelector(PROGRAMS_SELECTORS_BY_ROLES[currentRole]);
  const groupsLoading = useSelector(PROGRAMS_IS_LOADING_BY_ROLES[currentRole]);

  const { t } = useTranslation();

  const isDesktop = useMediaQuery(DESKTOP_VIEW);

  useEffect(() => {
    groups?.length === 0 && store.dispatch(PROGRAMS_FETCH_BY_ROLES[currentRole]);
  }, [currentRole]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PageWrapper>
      {isDesktop && (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <HeaderList title={t('types.submission.namePlural')} />
        </ErrorBoundary>
      )}
      {groupsLoading && <Loader />}
      {!groupsLoading && (
        <>
          {groups.length === 0 ? (
            <NoGroup />
          ) : (
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              gap={2}
              height="100%"
              mt={isDesktop ? 0 : 2}
            >
              <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
                <SubmissionsNavigation groups={groups} />
              </ErrorBoundary>
            </Stack>
          )}
        </>
      )}
    </PageWrapper>
  );
};

export default memo(SubmissionsPage);
