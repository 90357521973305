import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import store from 'store';
import { userSelectors } from 'store/ducks/user';

import { PageViewPaperVioletLight, PageWrapper } from 'views/common/styledComponents';
import Loader from 'views/common/Loader';
import HeaderList from 'views/common/HeaderList';
import ProgramsListTopBar from '../../common/ProgramsListTopBar';
import GroupsList from './components/GroupsList';

import {
  PROGRAMS_FETCH_BY_ROLES,
  PROGRAMS_IS_LOADING_BY_ROLES,
  PROGRAMS_SELECTORS_BY_ROLES,
} from 'utils/constants/program';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useMediaQuery } from '@mui/material';
import { DESKTOP_VIEW, MOBILE_VIEW } from 'utils/constants/common';
import HeaderPageMobile from 'views/common/HeaderPageMobile';
import { useTranslation } from 'react-i18next';
import NoGroup from '../../common/NoGroup';
import { ErrorBoundary } from '@sentry/react';
import Error from 'views/common/Error';
import { searchItems, sortPrograms } from 'utils/helpers';

const GroupsPage = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const searchPhrase = searchParams.get('q') || '';

  const { t } = useTranslation();

  const currentRole = useSelector(userSelectors.getCurrentRole());
  const groups = useSelector(PROGRAMS_SELECTORS_BY_ROLES[currentRole]);
  const isLoading = useSelector(PROGRAMS_IS_LOADING_BY_ROLES[currentRole]);

  const [sortBy, setSortBy] = useState('Default');

  const isDesktop = useMediaQuery(DESKTOP_VIEW);
  const isMobile = useMediaQuery(MOBILE_VIEW);

  const handleSearch = () => {
    return searchItems(groups, searchPhrase);
  };

  const filteredGroups = handleSearch();
  const sortedGroups = sortPrograms(filteredGroups, sortBy);

  const handleCardClick = (groupId, id) => {
    navigate(`/groups/${groupId}/programs/${id}`);
  };

  useEffect(() => {
    groups?.length === 0 && store.dispatch(PROGRAMS_FETCH_BY_ROLES[currentRole]);
  }, [currentRole]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PageWrapper>
      {isDesktop && (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <HeaderList
            title={t('types.group.namePlural')}
            isDisplaySearchBar
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            handleSearch={handleSearch}
          />
        </ErrorBoundary>
      )}
      {!isDesktop && (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <HeaderPageMobile
            isDisplaySearchBar
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            handleSearch={handleSearch}
          />
        </ErrorBoundary>
      )}
      {isLoading && <Loader />}
      {!isLoading && (
        <PageViewPaperVioletLight>
          {groups.length !== 0 ? (
            <>
              <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
                <ProgramsListTopBar sortBy={sortBy} setSortBy={setSortBy} hideTopBar={isMobile} />
              </ErrorBoundary>
              <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
                <GroupsList groups={sortedGroups} handleCardClick={handleCardClick} />
              </ErrorBoundary>
            </>
          ) : (
            <NoGroup />
          )}
        </PageViewPaperVioletLight>
      )}
    </PageWrapper>
  );
};

export default memo(GroupsPage);
