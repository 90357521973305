import { memo, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Stack, useMediaQuery } from '@mui/material';

import store from 'store';
import { useSelector } from 'react-redux';
import { userSelectors } from 'store/ducks/user';
import { lessonThunks } from 'store/ducks/lesson';

import {
  PROGRAM_FETCH_BY_ROLES,
  PROGRAM_HAS_ERROR_BY_ROLES,
  PROGRAM_IS_LOADING_BY_ROLES,
  PROGRAM_SELECTORS_BY_ROLES,
  PROGRAMS_SELECTORS_BY_ROLES,
} from 'utils/constants/program';

import HeaderList from 'views/common/HeaderList';
import ProgramNavigation from './components/navigation/ProgramNavigation';
import Loader from 'views/common/Loader';
import UnpaidInvoice from './components/programs/UnpaidInvoice';
import { DESKTOP_VIEW } from 'utils/constants/common';
import { PageWrapper } from 'views/common/styledComponents';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '@sentry/react';
import Error from 'views/common/Error';
import { studentActions } from 'store/ducks/student';

const ProgramPage = () => {
  const currentRole = useSelector(userSelectors.getCurrentRole());
  const { id, groupId } = useParams();

  const { t } = useTranslation();

  const isAdmin = currentRole === 'Admin';
  const isEdStuff = currentRole === 'Trainer' || currentRole === 'Mentor';

  const program = useSelector(PROGRAM_SELECTORS_BY_ROLES[currentRole]);
  const isLoading = useSelector(PROGRAM_IS_LOADING_BY_ROLES[currentRole]) && !program;
  const hasError = useSelector(PROGRAM_HAS_ERROR_BY_ROLES[currentRole]);

  const programs = useSelector(PROGRAMS_SELECTORS_BY_ROLES[currentRole]);

  const isDesktop = useMediaQuery(DESKTOP_VIEW);

  useEffect(() => {
    if (currentRole === 'Student' && program && programs.length > 0) {
      const currentProgram = programs?.find((p) => p.id === program?.id);

      if (currentProgram?.completion !== program?.completion) {
        store.dispatch(
          studentActions.replaceItem({
            collection: 'programs',
            data: { ...currentProgram, completion: program.completion },
          })
        );
      }
    }
  }, [currentRole, program, programs]);

  useEffect(() => {
    if (isEdStuff) {
      store.dispatch(PROGRAM_FETCH_BY_ROLES[currentRole](groupId));
    } else store.dispatch(PROGRAM_FETCH_BY_ROLES[currentRole](id));

    isAdmin && store.dispatch(lessonThunks.fetchLessons(id));
  }, [currentRole, id, groupId, isAdmin, isEdStuff]);

  return (
    <PageWrapper>
      {isDesktop && (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <HeaderList title={t('types.program.name')} />
        </ErrorBoundary>
      )}
      {isLoading && <Loader />}
      {!isLoading &&
        !hasError &&
        (program?.modules.length === 0 ? (
          <UnpaidInvoice />
        ) : (
          <Stack
            flexDirection="row"
            alignItems="flex-start"
            justifyContent="center"
            gap={2}
            mt={isDesktop ? 0 : 2}
            height="100%"
            sx={{ overflowY: 'hidden' }}
          >
            <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
              <ProgramNavigation program={program} />
            </ErrorBoundary>
          </Stack>
        ))}
    </PageWrapper>
  );
};

export default memo(ProgramPage);
