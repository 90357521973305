import { memo, useEffect } from 'react';
import { ButtonBase, Stack, useMediaQuery } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate, useParams } from 'react-router-dom';

import ProgramProgressBar from 'views/common/ProgramProgressBar';
import { useSelector } from 'react-redux';
import { userSelectors } from 'store/ducks/user';
import { DESKTOP_VIEW } from 'utils/constants/common';
import { useProgramMobileMenuOpenContext } from 'services/context/programMobileMenuOpenContext';

const GradesNavigation = ({ programs }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const currentRole = useSelector(userSelectors.getCurrentRole());
  const isStudent = currentRole === 'Student';

  const isDesktop = useMediaQuery(DESKTOP_VIEW);

  const { toggleProgramMobileMenu } = useProgramMobileMenuOpenContext();

  const toggleSelected = (program) => {
    if (program?.id === +id) {
      !isDesktop && toggleProgramMobileMenu();
      return;
    }
    !isDesktop && toggleProgramMobileMenu();
    navigate(`/grades/programs/${program.id}`);
  };

  useEffect(() => {
    isDesktop &&
      programs?.length > 1 &&
      (id ? navigate(`/grades/programs/${id}`) : navigate(`/grades/programs/${programs[0].id}`));
  }, [isDesktop, programs, navigate, id]);

  return (
    <Stack flex={isDesktop ? 0.3 : 1} height="100%" direction="column" gap={2} sx={{ overflowY: 'auto' }}>
      {programs?.map((program) => (
        <ButtonBase
          sx={{
            backgroundColor: 'extra.white',
            p: 2,
            borderRadius: 2,
            border: '1px solid transparent',
            '&:hover': { border: '1px solid', borderColor: 'primary.main' },
          }}
          px={2}
          pt={2}
          pb={1}
          key={program.name}
          id={program.name}
          onClick={() => toggleSelected(program)}
        >
          {isStudent && (
            <ProgramProgressBar
              program={program}
              programProgress={program?.completion}
            />
          )}
          {+id === program?.id && <ArrowForwardIosIcon sx={{ color: 'primary.main', ml: 2 }} />}
        </ButtonBase>
      ))}
    </Stack>
  );
};

export default memo(GradesNavigation);
