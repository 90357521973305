/** ---- View of grades for STUDENT role --- */

import { memo, useEffect } from 'react';
import { Stack, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import GradesNavigation from './components/GradesNavigation';
import HeaderList from 'views/common/HeaderList';
import { useSelector } from 'react-redux';
import { userSelectors } from 'store/ducks/user';
import {
  PROGRAMS_FETCH_BY_ROLES,
  PROGRAMS_IS_LOADING_BY_ROLES,
  PROGRAMS_SELECTORS_BY_ROLES,
} from 'utils/constants/program';
import store from 'store';
import Loader from 'views/common/Loader';
import { DESKTOP_VIEW } from 'utils/constants/common';
import { useProgramMobileMenuOpenContext } from 'services/context/programMobileMenuOpenContext';
import { PageWrapper } from 'views/common/styledComponents';
import HeaderPageMobile from 'views/common/HeaderPageMobile';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '@sentry/react';
import Error from 'views/common/Error';

const ProgramsListOfGradesPage = () => {
  const navigate = useNavigate();

  const currentRole = useSelector(userSelectors.getCurrentRole());

  const isDesktop = useMediaQuery(DESKTOP_VIEW);
  const { isProgramMenuOpen, toggleProgramMobileMenu } = useProgramMobileMenuOpenContext();

  const programs = useSelector(PROGRAMS_SELECTORS_BY_ROLES[currentRole]);
  const programsLoading = useSelector(PROGRAMS_IS_LOADING_BY_ROLES[currentRole]);

  const { t } = useTranslation();

  /** ---- Fetch all programs --- */
  useEffect(() => {
    programs?.length === 0 && store.dispatch(PROGRAMS_FETCH_BY_ROLES[currentRole]);
  }, [currentRole]); // eslint-disable-line react-hooks/exhaustive-deps

  /** --- If student has only one program we open this program without displaying nav menu ---  */
  useEffect(() => {
    if (programs?.length !== 0 && programs?.length === 1) {
      navigate(`/grades/programs/${programs[0].id}`);
    }
  }, [programs, navigate, toggleProgramMobileMenu]);

  /** --- If the pge is opened on Mobile then change isProgramMenuOpen to true, in order to display the programs list --- */
  useEffect(() => {
    if (!isDesktop && programs?.length > 1 && !isProgramMenuOpen) {
      toggleProgramMobileMenu();
    }
  }, [isDesktop, toggleProgramMobileMenu, programs, isProgramMenuOpen]);

  return (
    <PageWrapper>
      {isDesktop && (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <HeaderList title={t('types.grade.namePlural')} />
        </ErrorBoundary>
      )}
      {!isDesktop && (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <HeaderPageMobile
            isToShowProgramMenuBtn={programs?.length > 1 ? true : false}
            selectedMaterialPriorityLabel={t('types.grade.selectProgram')}
            toggleProgramMobileMenu={toggleProgramMobileMenu}
          />
        </ErrorBoundary>
      )}
      {programsLoading && <Loader />}
      {!programsLoading && (
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          gap={2}
          height="100%"
          sx={{ overflowY: 'auto' }}
        >
          {((isDesktop && programs?.length > 1) || isProgramMenuOpen) && (
            <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
              <GradesNavigation programs={programs} />
            </ErrorBoundary>
          )}
        </Stack>
      )}
    </PageWrapper>
  );
};

export default memo(ProgramsListOfGradesPage);
